export const GOOGLE_ONE_TAP_DEBUG_FEATURE_FLAG: string = "printConsoleDebug";
export const CORE_MARKET_STATS_AMPLITUDE_EVENTS_FEATURE_FLAG: string = "LT3924";
export const LAMUDI_MULTI_LOCATION_MODAL_FILTER_FEATURE_FLAG: string = "3843";
export const HIPFLAT_WEB_COMPONENT_FEATURE_FLAG: string = "webcomponent";
export const TROVIT_USER_SEARCH_FEATURE_FLAG: string = "PROD-10694";

export default class FeatureFlags {
    isActiveFeature = (featureName: string): boolean => {
        const urlParams = new URLSearchParams(window.location.search);
        const feature = Boolean(urlParams.get(featureName))
        if (feature) {
            return true;
        }
        const cookie = document.cookie;
        return cookie.includes(featureName);
    }
};